import React, {useState} from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'

const Services = (props) => {
  const {setMessage} = props;

  const includedFeatures = [
    {title:'Reconciling Only (Data Entry not included)', price: 150},
    {title:'Payroll Processing $100 up to 5 employees additional $15 per employee after 5', price: 100},
    {title:'B&O Taxes / City & State Excise Tax', price: 125},
    {title:'Notary Service (existing clients only)', price: 10},
    {title:'Quickbooks Training/Setup', price: 450},
  ]
  const [price, setPrice] = useState(0);
  const [services, setServices] = useState([]);

  const handlePrice = (e) => {
    let totalPrice = 0;

    if (e.target.checked) {
      totalPrice = Number(e.target.value) + price;
      setServices([...services, e.target.name]);
    } else {
      totalPrice = price - Number(e.target.value);
      setServices(services.filter((service) => service !== e.target.name));
    }
    setPrice(totalPrice);
  }
  const handleSubmit = (e) => {
    const servicesString = services.join(', ');
    setMessage('Hello, I am interested in building my own package with ' + servicesString +' services with a base package total of $' + price + '. Please contact me at your earliest convenience.');
  }

    return (
      
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 bg-white ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Build Your Own Package</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Our stand-alone services are available to fit your needs.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Services</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>

              <div
                className="mt-8 grid grid-cols-2 text-sm text-gray-600"
              >

                {includedFeatures.map((feature) => (
                  <div className='mt-8 inline-flex' key={feature.title}>
                  <input type='checkbox' value={feature.price} name={feature.title} className="flex mr-2" onChange={(e) => handlePrice(e)}/>
                  <label htmlFor={feature.title} className="flex">
                    {feature.title}<b>${feature.price}</b>
                  </label>
                  </div>
                ))}
              </div>

            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">Pay monthly, cancel anytime</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">${price}</span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                  </p>
                  <a
                    href='#contact'
                    onClick={(e) => handleSubmit(e)}
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get access
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Prices not guaranteed until reviewed by Overall Office Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
    )
  }

export default Services