// import React from "react";

// const logo = require("../images/OverallOfficeSolutions_logo.png");
// const f_logo = require("../images/social_logos/f_logo_RGB-White_100.png");
// const t_logo = require("../images/social_logos/twitter.png");
// const l_logo = require("../images/social_logos/linkedin.png");

import React from 'react';
import { useState } from 'react';
import logo from '../images/OverallOfficeSolutions_logo.png';
import f_logo from '../images/social_logos/f_logo_RGB-White_100.png';
import t_logo from '../images/social_logos/twitter.png';
import l_logo from '../images/social_logos/linkedin.png';

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="bg-stone-100 p-4 md:p-12 flex flex-col md:flex-row justify-center md:justify-between items-center w-screen">
      <div className="flex items-center justify-between mb-2 md:mb-0 ">
        <a href="#about" className={`text-lg px-4 ${showMenu ? 'hidden md:block' : 'block'}`}><b>About</b></a>
        <a href="#services" className={`text-lg px-4 ${showMenu ? 'hidden md:block' : 'block'}`}><b>Rates & Services</b></a>
        <a href="#contact" className={`text-lg px-4 ${showMenu ? 'hidden md:block' : 'block'}`}><b>Contact Us</b></a>
      </div>
      <button
        className={`md:hidden`}
        onClick={toggleMenu}
      >
        ☰
      </button>
      {/* <h1 className="font-extrabold text-stone-500 text-center md:text-left  my-2 md:my-0">
        Overall Office Solutions
      </h1> */}
      <img src={logo} alt="Overall Office Solutions Logo" className="md:w-60 w-48" />
      <div className="flex items-center ">
        <p className="text-stone-500 text-xl font-bold mr-4">
          &#10088;206&#10089; 605-8762
        </p>
        <a
          href="https://www.facebook.com/overallos/?fref=ts"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-2"
        >
          <img
            className="w-6 h-6 hover:cursor-pointer bg-slate-500 hover:bg-slate-600 p-0.5 rounded-full"
            src={f_logo}
            alt="Facebook Logo"
          />
        </a>
        {/* <a
          href="https://twitter.com/MorelliNancy"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-2"
        >
          <img
            className="w-6 h-6 hover:cursor-pointer bg-slate-500 hover:bg-slate-600 p-0.5 rounded-full"
            src={t_logo}
            alt="Twitter Logo"
          />
        </a> */}
        <a
          href="https://www.linkedin.com/in/nancy-morelli-a30bb032/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="w-6 h-6 hover:cursor-pointer bg-slate-500 hover:bg-slate-600 p-0.5 rounded-full"
            src={l_logo}
            alt="Linkedin Logo"
          />
        </a>
      </div>
    </div>
  );
};


export default Header;
