const stats = [
    { id: 1, name: 'Business Experience', value: 'Over 20 Years' },
    { id: 2, name: 'QuickBooks Pro Advisor', value: 'Since 2007' },
    { id: 3, name: 'Clients Served', value: '100+' },
  ]
  
  export default function Stats() {
    return (
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3 ">
            {stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex flex-col gap-y-4 rounded-3xl ring-1 bg-white ring-gray-200 w-full p-12">
                <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
  