export default function Testimonials() {
    return (
      <div className="flex justify-center">
      <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-20 lg:px-8 rounded-3xl w-2/3">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        {/* <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" /> */}
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <figure className="mt-10">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <p>
                “Since July of 2009, Overall Office Solutions has provided the needed expertise to keep our small business afloat in these tough economic times.  This includes, – data entry on QuickBooks, – verifying/auditing expenses and sales on a monthly basis, – ensuring that excise taxes are calculated correctly and paid on time, – designing and implementing excel spreadsheets that allow us to keep our manufacture representatives updated and vendor contact base viable.  Nancy has the training and knowledge base that is continually upgraded with the appropriate schooling that keep her consistent with her goal to provide effective services in the accounting and tax preparation field.  Nancy has her customers well being in mind and is extremely flexible in her hours of operation.”
              </p>
            </blockquote>
            <figcaption className="mt-10">
              <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                <div className="font-semibold text-gray-900">John Curry</div>
                <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div className="text-gray-600">President of Bridjit Curb Ramps</div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
      </div>
    )
  }
  