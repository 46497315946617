import React, {useState} from 'react';
import Header from './components/Header'
import nancy from './images/nancy.jpg';
import { ContactUs } from './components/ContactUs';
import Prices from './components/Prices';
import Services from './components/Services';
import Stats from './components/Stats';
import qucikbooks from './images/QB-ProAdvisor-Badge.png';
import Testimonials from './components/Testimonials';

const HomePage = () => {
  const [message , setMessage] = useState('')
  return (
    <div>
      <Header />
      <div className="hero-image flex justify-center">
        {/* <img src={bg_image} alt="Hero Image" className="w-full h-auto flex" /> */}
        <div className="text-4xl font-bold text-white text-center mt-20 mask1"></div>
      </div>
      <div className="container mx-auto pt-12 px-4 pb-12 md:pb-20" id="about">
        <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-12'>
        <div className='sm:w-0 sm:h-0 w-[100px] h-[70px] col-span-1'></div>
        <div className=' flex justify-center col-span-12 md:col-span-4 pb-4'>
          <img src={nancy} alt="Owner" className='w-[350px] h-[350px] rounded-full'/>
          </div>
          <div className='col-span-7'>
          <div className='text-left'>
            <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Featured Services</h2>
            <p className="text-gray-600 text-lg">Overall Office Solutions is a team of accountants who specialize in
            bookkeeping for small business and small non profit organizations. We specialize in taylor made bookkeeping for each client to meet their organizational
            accounting needs.</p>
          </div>
          <hr className='my-6'/>
          <div className='text-left'>
            <h2 className="text-2xl font-semibold mb-4 text-indigo-600">About the Owner</h2>
            <p className="text-gray-600 text-lg">Nancy Morelli, is an accomplished professional with a Bachelor of Science in Accounting from City University, an MBA from Keller School of Management, and the distinction of being a QuickBooks Pro Advisor. With over two decades in the administrative and accounting field, Nancy is dedicated to simplifying financial records for clients' comprehension. Actively engaged in the QuickSource Meet-Up group, she stays current with accounting trends and technology.</p>
            </div>
            <hr className='my-6'/>
          <div className='text-left'>
            <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Service Area</h2>
            <p className="text-gray-600 text-lg">South King County, Washington State serving: Seattle, Des Moines, Tacoma, Kent, Renton, Federal Way, Burien, Tukwila, Seatac, Fife, Covington, Auburn</p>
            </div>
          </div>
        </div>
      </div>
      <Testimonials />
      <Stats />
      <div id="services">
      <div className="py-16 sm:py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We believe in honest business and that starts with being upfront about pricing. We don’t charge any hidden fees. Choose a plan that works for you.
            </p>
          </div>
      <Prices includedFeatures={  [
        'Data Entry',
        'Reconciling bank statements',
        'Financial reports'
      ]} title={"Basic Bookkeeping Package"} price={"$275"} description={"Our basic package to keep you running so you don't have to worry."} setMessage={setMessage}/>
      <Prices includedFeatures={  [
        'Data Entry',
    'Reconciling bank statements',
    'Financial reports',
    'Monthly/quarterly city and state local tax filings',
    'Prepare and file year-end 1099 tax forms'
  ]} title={"Mid-Level Bookkeeping Package"} price={"$375"} description={"Our mid-level package includes all the features of basic with the added benefit of preparing and filing both state tax filings and the year-end 1099 tax forms."} setMessage={setMessage}/>
      <Prices includedFeatures={  [
        'Data Entry',
        'Reconciling bank statements',
    'Financial reports',
    'Monthly/quarterly city and state local tax filings',
    'Prepare and file year-end 1099 tax forms',
    'Payroll',
    'Payroll tax filings',
    'Year-end tax reporting',
    'Book preparation for CPA, EA or Tax Preparer'
  ]} title={"Full-Service Bookkeeping Package"} price={"$475"} description={"Our full-service package includes all the features of mid-level with the added benefit of payroll, payroll tax filings, year-end tax reporting and book preparation for CPA, EA or Tax Preparer."} setMessage={setMessage}/>
  <Services setMessage={setMessage} />
  </div>
  </div>

  </div>
  <div id="contact">
      <ContactUs message={message} setMessage={setMessage}/>
  </div>
      <div className="container p-4 flex row">
        <a href="https://www.bbb.org/us/wa/auburn/profile/bookkeeping-services/overall-office-solutions-llc-1296-22727950" target='_blank'>
          <img id="bbblinkimg" src="http://seal-alaskaoregonwesternwashington.bbb.org/logo/ruvtbum/overall-office-solutions-22727950.png" alt="Overall Office Solutions LLC, Bookkeeping Service, Kent, WA"/>
        </a>
      <img src={qucikbooks} alt="Quickbooks Pro Advisor" className='w-[150px] h-[150px] rounded-full mx-4'/>
      </div>
    </div>
  );
};

export default HomePage;
